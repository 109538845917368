import React from 'react';
import styled from '@emotion/styled';
import Link from '@/components/Base/Links';
import Layout from '@/components/Base/Layout';
import SEO from '@/components/Base/seo';
import Container from '@/components/Base/Container/PageContainer';

const MiddleGround = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Страница не найдена - 404" />
    <MiddleGround>
      <h1>Страница не найдена</h1>
      <Link to="/">Вернуться на домашнюю страницу</Link>
    </MiddleGround>
  </Layout>
);

export default NotFoundPage;
